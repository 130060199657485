import 'core-js/stable';

$(document).ready(async () => {
  $("#geocoder input[type='text']").prop('disabled', true);

  $('.exchange_button').on('click', function() {
    $('.exchange_button').prop('disabled', false);

    $(this).prop('disabled', true);
    $(this).css('cursor', 'pointer');

    const product_id = $(this).attr('data-id');
    const product_quantity = parseInt($(this).attr('data-quantity'));

    var qntty_blck = '';
    for (let i = 1; i <= product_quantity; i++) {
      qntty_blck += '<option>' + i + '</option>';
    }

    $('.exchange_count').prop('disabled', false);
    $('#reasonId select').prop('disabled', false);

    $('.exchange_count').html(qntty_blck);
    $('.exchange-blck').css('display', 'block');

    $('.cancel_exchange').attr('data-id', product_id);

    $('#order_table td').parent().find('select').prop('disabled', true);

    $('.save_reason').attr('data-cost', parseFloat($(this).attr('data-cost')));
    $('.search-exchange-product').css('display', 'none');
  });

  $('.exchange_count').on('change', function() {
      let quantity = $(this).val();
      let pr_id = $('.cancel_exchange').attr('data-id');
      let cost = parseFloat($('#exchange_' + pr_id).attr('data-cost')) * parseInt(quantity);
      $('.save_reason').attr('data-cost', cost);
  });

  $('.cancel_exchange').on('click', function() {
    $('.exchange_count').html('');
    $('.exchange-blck').css('display', 'none');

    var pr_id = $(this).attr('data-id');
    $('#exchange_' + pr_id).prop('disabled', false);

    $('.save_reason').removeAttr('data-cost');
    $('#current_cost').attr('value', 0);
    $(this).removeAttr('data-id');

    $('.search-exchange-product').css('display', 'none');
    $('.old-product-' + pr_id).parent().find('select').prop('disabled', true);
  });

  $('.save_reason').on('click', function() {
    $('.old-product-' + $('.cancel_exchange').attr('data-id') + ' .remove_button').trigger('click');
    $('#reasonId select').prop('disabled', true);
    $('.exchange_count').prop('disabled', true);

    $('.search-exchange-product').css('display', 'block');
    const qnty = parseInt($('.exchange_count').val());
    const cost = parseFloat($('.save_reason').attr('data-cost'));

    $('#current_cost').attr('value', 0);
    $('.save_reason').attr('data-cost', cost);
  });
});
